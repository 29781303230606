import { Controller } from '@hotwired/stimulus'

// Connects to data-controller="search-rag"
export default class extends Controller {
  static targets = ['selectedType', 'query', 'resetButton']
  static values = {
    searchPath: String,
    formMethod: String,
    searchType: String,
  }

  connect () {
    this.selectedTypeTarget.textContent = 'Selected: Full Text'
    // Flag to allow empty query search when resetting
    this.resetAll = false
  }

  setSearchType (event) {
    event.preventDefault()
    const type = event.currentTarget.dataset.searchType

    this.selectedTypeTarget.textContent = type === 'full_text'
      ? 'Selected: Full Text'
      : 'Selected: RAG'
    this.searchTypeValue = type
    this.search()
  }

  async search () {
    const query = this.queryTarget.value
    const searchType = this.searchTypeValue

    // Allow empty search only when resetting to clear results
    if (query.length < 2 && !this.resetAll) return

    try {
      const response = await fetch(this.searchPathValue, {
        method: this.formMethodValue,
        headers: this.getHeaders(),
        body: JSON.stringify({
          query: query,
          search_type: searchType,
        }),
      })

      const html = await response.text()
      Turbo.renderStreamMessage(html)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  getHeaders () {
    return {
      'Accept': 'text/vnd.turbo-stream.html',
      'Content-Type': 'application/json',
      'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
    }
  }

  validateQuery () {
    const query = this.queryTarget.value

    if (query.length < 3) {
      this.queryTarget.classList.add('input-error')
    } else {
      this.queryTarget.classList.remove('input-error')
    }

    // Remove the automatic search trigger
    this.resetButtonTarget.classList.toggle('hidden', !this.queryTarget.value)
  }

  handleKeypress (event) {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (this.queryTarget.value.length >= 3) {
        this.search()
      }
    }
  }

  resetSearch () {
    this.resetAll = true
    this.queryTarget.value = ''
    this.searchTypeValue = 'full_text'
    this.resetButtonTarget.classList.add('hidden')
    this.selectedTypeTarget.textContent = 'Selected: Full Text'
    this.search()
  }
}
