import { Controller } from '@hotwired/stimulus'
import Toastify from 'toastify-js'

// Connects to data-controller="clipboard"
export default class extends Controller {
  static targets = ['text']

  copy (event) {
    event.preventDefault()
    const text = this.textTarget.textContent.trim()
    navigator.clipboard.writeText(text)
    Toastify({
      text: 'Copied to clipboard',
      duration: 3000,
      gravity: 'top',
      position: 'right',
      close: true,
    }).showToast()
  }
}


