import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    // Check if user has a stored preference, otherwise use system preference
    const storedTheme = localStorage.getItem('theme')
    const theme = storedTheme || (this.prefersDark() ? 'dark' : 'winter')

    document.documentElement.setAttribute('data-theme', theme)
    this.element.querySelector('input').checked = theme === 'dark'
  }

  toggle (event) {
    const theme = event.target.checked ? 'dark' : 'winter'
    localStorage.setItem('theme', theme)
    document.documentElement.setAttribute('data-theme', theme)
  }

  // Helper method to check system dark mode preference
  prefersDark () {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
  }
}