import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form', 'input', 'clearButton']

  search () {
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.requestSubmit()
      this.toggleClearButton()
    }, 200)
  }

  clear () {
    this.inputTarget.value = ''
    this.toggleClearButton()
    this.formTarget.requestSubmit()
  }

  toggleClearButton () {
    const hasValue = this.inputTarget.value.length > 0
    this.clearButtonTarget.classList.toggle('hidden', !hasValue)
  }
}
