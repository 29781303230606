import { Application } from '@hotwired/stimulus'
import Toastify from 'toastify-js'

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus = application

export { application }

Turbo.StreamActions.redirect_to_turbo_stream = function () {
  console.log(this.target)
  Turbo.visit(this.target)
}

Turbo.StreamActions.toast = function () {
  const text = this.getAttribute('text')
  const duration = Number(this.getAttribute('duration'))
  const gravity = this.getAttribute('gravity')
  const position = this.getAttribute('position')
  const close = this.getAttribute('close') === 'true'

  const toast = Toastify({
    text,
    duration,
    gravity,
    position,
    close,
  })

  toast.showToast()
}