import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="modal"
export default class extends Controller {
  connect() {
    this.element.show()
    // Add event listener for escape key
    document.addEventListener("keydown", this.handleEscape.bind(this))
  }

  disconnect() {
    // Clean up event listener when controller disconnects
    document.removeEventListener("keydown", this.handleEscape.bind(this))
  }

  close() {
    this.element.close()
  }

  handleEscape(event) {
    if (event.key === "Escape") {
      this.close()
    }
  }
}
