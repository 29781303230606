import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["monthlyPrice", "yearlyPrice"]
  
  connect() {
    this.isYearly = false
  }

  toggle() {
    this.isYearly = !this.isYearly
    this.monthlyPriceTargets.forEach(el => el.classList.toggle('hidden'))
    this.yearlyPriceTargets.forEach(el => el.classList.toggle('hidden'))
  }

  selectPlan(event) {
    const planId = event.currentTarget.dataset.planId
    const interval = this.isYearly ? 'yearly' : 'monthly'
    const link = event.currentTarget
    const destUrl = new URL(link.href)
    
    destUrl.searchParams.set('plan_id', planId)
    destUrl.searchParams.set('interval', interval)
    
    link.href = destUrl.toString()
  }
} 