import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="quick-results"
export default class extends Controller {
  static targets = ['input', 'remove', 'list', 'result']
  static values = {
    url: String
  }
  
  connect() {
    this.removeTarget.classList.add('hidden')
    this.listTarget.classList.add('hidden')
  }

  async fetchQuickResult (event) {
    try {
      const url = new URL(this.urlValue, window.location.origin);
      url.searchParams.append('agent', event.params.agent);

      const response = await fetch(url, {
        method: 'GET',
        headers: {
          'Turbo-Frame': 'quick_results'
        },
      })

      const html = await response.text()
      Turbo.renderStreamMessage(html)
    } catch (error) {
      console.error('Error:', error)
    }
  }


  search() {
    const query = this.inputTarget.value.toLowerCase()
    const items = this.resultTargets
    let hasVisibleItems = false
    
    items.forEach(item => {
      const text = item.textContent.toLowerCase()
      const shouldShow = text.includes(query)
      item.classList.toggle('hidden', !shouldShow)
      hasVisibleItems = hasVisibleItems || shouldShow
    })

    this.listTarget.classList.toggle('hidden', !hasVisibleItems)
  }

  select(event) {
    event.preventDefault()
    const selectedItem = event.target.closest('li')
    this.inputTarget.value = selectedItem.textContent.trim()
    this.hideAll()
    this.removeTarget.classList.remove('hidden')
    this.inputTarget.disabled = true
    this.fetchQuickResult(event)
  }

  reset() {
    this.inputTarget.value = ''
    this.removeTarget.classList.add('hidden')
    this.inputTarget.disabled = false
    this.hideAll()
  }

  hideAll() {
    this.resultTargets.forEach(item => {
      item.classList.add('hidden')
    })
    this.listTarget.classList.add('hidden')
    this.inputTarget.disabled = false
  }
}
